import React from "react"
import tw from "twin.macro"
import { SectionHeading as HeadingTitle } from "./misc/Headings.jsx"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto my-6 lg:my-6`
const HeadingInfoContainer = tw.div`flex flex-col items-center`
const NewsContainer = tw.div`h-128 overflow-y-auto border border-gray-300 rounded-lg p-4 w-full bg-white`
const Details = tw.div`p-4   border-b-2 border-solid border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`
const Title = tw.div`mt-4 leading-snug font-bold text-lg`
const News = tw.a`mt-2 text-sm text-secondary-100`

export default () => {
  const newsPosts = [
    {
      date: "2024/06/25",
      news: "Rossoのエンジニアが「2024 Japan AWS All Certifications Engineers」に選出されました",
      url: "https://www.rosso-tokyo.co.jp/rosso2024-japan-aws20240625/",
    },
    {
      date: "2023/4/24",
      news: "Rossoのエンジニアが、「2023 Japan AWS All Certifications Engineers」に選出されました。",
      url: "https://www.rosso-tokyo.co.jp/rosso%e3%81%ae%e3%82%a8%e3%83%b3%e3%82%b8%e3%83%8b%e3%82%a2%e3%81%8c%e3%80%81%e3%80%8c2023-japan-aws-all-certifications-engineers%e3%80%8d%e3%81%ab%e9%81%b8%e5%87%ba%e3%81%95%e3%82%8c%e3%81%be/",
    },
    {
      date: "2022/08/31",
      news: "気象×デジタルサイネージ「SPOT INFO NOTICE(R)」―地球の鼓動を、みる時代―において、 Rossoがシステム開発で技術支援",
      url: "https://www.rosso-tokyo.co.jp/%e6%b0%97%e8%b1%a1x%e3%83%87%e3%82%b8%e3%82%bf%e3%83%ab%e3%82%b5%e3%82%a4%e3%83%8d%e3%83%bc%e3%82%b8%e3%80%8cspot-info-noticer%e3%80%8d%e2%80%95%e5%9c%b0%e7%90%83%e3%81%ae%e9%bc%93%e5%8b%95/",
    },
    {
      date: "2022/02/28",
      news: "「サクッとAI」と「サクッとクラウド」の リリース1周年を記念して“AI・クラウド何でも無料相談会”を開催",
      url: "https://www.rosso-tokyo.co.jp/%e4%bc%81%e6%a5%ad%e3%81%aedx%e6%8e%a8%e9%80%b2%e3%82%92%e6%94%af%e6%8f%b4%e3%81%99%e3%82%8b%e3%80%8c%e3%82%b5%e3%82%af%e3%83%83%e3%81%a8ai%e3%80%8d%e3%81%a8%e3%80%8c%e3%82%b5%e3%82%af%e3%83%83/",
    },
    {
      date: "2022/01/20",
      news: "Rosso、手軽に導入可能なDXソリューション 「サクッとAI」「サクッとクラウド」の商標登録完了のお知らせ",
      url: "https://www.atpress.ne.jp/news/291797",
    },
  ]
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle id="news">News</HeadingTitle>
        </HeadingInfoContainer>
        <NewsContainer>
          {newsPosts.map((post, index) => (
            <Details key={index}>
              <Title>{post.date}</Title>
              <News href={post.url} target="_blank" rel="noopener noreferrer">
                {post.news}
              </News>
            </Details>
          ))}
        </NewsContainer>
      </Content>
    </Container>
  )
}
